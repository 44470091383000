import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { fetchServices, SourcesState } from '../../../redux/slices/sourceSlice';
import { contentSourceTypes } from '../../../types/Item';
import { additionalSourceFields, DynamicSource } from '../../../types/DynamicSource';
import { createDynamicUrl } from '../../../utils/fnUrl';
import { validator } from '../../../utils/fnValidator';
import {
    FilterParamOptionsLabels,
    MultipleSelectFields,
    ServiceParamDefaultValues,
    ServiceParamNames,
    ServiceParamOptionLabels,
    ServiceSubserviceNames,
    StingrayGenresLabels
} from '../../../utils/Services';
import GenericDialog, {
    DialogButton,
    DialogDropdownMultiple,
    DialogDropdownSingle,
    DialogTextField,
    DialogToggleButton,
    DialogTypes
} from '../../common/Dialog/GenericDialog';
import { InputLabelWithIconWrapper } from '../../common/Dialog/GenericDialog.css';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import { ParamsContainer } from './NewSource.css';
import YoutubeSourceFields, { YOUTUBE_FIELD_LABELS_MAP, YOUTUBE_METHOD_KEYS, YOUTUBE_RECO_SHELF_PARAMS } from './YoutubeSourceFields';
import { removeEmptyParams } from '../../../utils/fnData';
import { capitalizeAndSplitCamelCaseString } from '../../../utils/fnString';
import { DataPreviewDialog, DialogPreviewTypes } from '../../PageEdit/VisualElements/DataPreviewDialog';
import { Application, ApplicationDialog } from '../../Applications/ApplicationDialog';
import { createApplication, fetchApplications } from '../../../redux/slices/applicationsSlice';
import { AndroidSourceFields, multipleAndroidFieldMethods } from './AndroidSourceFields';
import { CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import DaznSourceFields, { DaznMethodKeys, DaznMethods, streamingRightsFilterKey } from './DaznSourceFields';
import { platformAssetstoreServices } from '../../../utils/Globals';
import PrimeAndDisneySourceFields, { PrimeAndDisneyMethodKeys, PrimeAndDisneyMethods } from './PrimeAndDisneySourceFields';
import PlatformAssetsSourceFields from './PlatformAssetsFields';

const FILTER_PARAM = 'filter';

type NewSourceDialogProps = {
    open: boolean;
    dynamicSource?: DynamicSource;
    onClose: () => void;
    onSave: (source: DynamicSource) => void;
    preselectedService?: string;
};

export const SWR3_LABELS = {
    'swr3 newszone': 'SWR3 Newszone',
    'swr3 unplugged': 'SWR3 Unplugged',
    'swr3 das ding': 'SWR3 Das Ding'
};

const horizonSportsDefaultAssetType = 'clip';
const tedTalksDefaultAssetType = 'podcast';
const swr3DefaultAssetType = 'dynamicEvent';

export const NewSourceDialog: FC<NewSourceDialogProps> = ({ open, onClose, onSave, dynamicSource, preselectedService }) => {
    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state) => state.activeItem);
    const [loadedServicesProjectId, setLoadedServicesProjectId] = useState<string | undefined>(undefined);
    const { services: Services, loading: servicesLoading }: SourcesState = useAppSelector((state) => state.dynamicSources);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [requiresSubService, setRequiresSubService] = useState(false);
    const [serviceOptions, setServiceOptions] = useState<any[]>([]);
    const [methodOptions, setMethodOptions] = useState<any[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [selectedService, setSelectedService] = useState<string>('');
    const [selectedSubService, setSelectedSubService] = useState<string>('');
    const [queryParamsValues, setQueryParamsValues] = useState<any>({});
    const [bodyParamsValues, setBodyParamsValues] = useState<any>({});
    const [pathParamsValues, setPathParamsValues] = useState<any>({});
    const [additionalFieldsValues, setAdditionalFieldsValues] = useState<any>({});
    const [filterParamsValues, setFilterParamsValues] = useState<any>({});
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [errors, setErrors] = useState<{
        service?: string;
        method?: string;
        name?: string;
        param?: string;
        bodyParams?: any;
        pathParams?: any;
        queryParams?: any;
    }>({});

    const [showNewApplicationDialog, setShowNewApplicationDialog] = useState<boolean>(false);
    const [addMultipleApps, setAddMultipleApps] = useState<boolean>(false);

    //DATA PREVIEW
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const loadServices = async (projectId: string) => {
        return await dispatch(fetchServices(projectId)).unwrap();
    };

    const loadApplications = async () => {
        return await dispatch(fetchApplications()).unwrap();
    };

    const createApp = async (application: Application) => {
        await dispatch(createApplication(application)).unwrap();
        const apps = await loadApplications();
        if (apps.error) return;
        // we update the app query with the new app value
        const querytoAdd = application.androidPackageName;

        setQueryParamsValues({
            ...queryParamsValues,
            apps: addMultipleApps ? [...(queryParamsValues?.apps || []), querytoAdd] : querytoAdd
        });
        setAddMultipleApps(false);
    };

    const clearParams = () => {
        setQueryParamsValues({});
        setBodyParamsValues({});
        setPathParamsValues({});
        setFilterParamsValues({});
        setAdditionalFieldsValues({});
    };

    const onCloseClick = () => {
        setSelectedService('');
        setSelectedMethod('');
        clearParams();
        setMethodOptions([]);
        setUrl('');
        setName('');
        setErrors({});
        onClose && onClose();
    };
    const onSaveClick = () => {
        if (!validateSource()) return;
        const source: DynamicSource = {
            _id: dynamicSource?._id || '',
            name,
            tenantId: activeTenantId || '',
            projectId: activeProjectId || '',
            service: selectedService,
            method: selectedMethod,
            url,
            lastModified: dynamicSource?.lastModified
        };
        if (selectedSubService) {
            source.subService = selectedSubService;
        }
        if (queryParamsValues) {
            let queryParams = removeEmptyParams(queryParamsValues);

            if (queryParams.apps) {
                const { apps } = queryParams;
                queryParams.apps = typeof apps === 'string' ? [apps] : apps;
            }

            if (!_.isEmpty(filterParamsValues)) {
                const newFilter = removeEmptyParams(filterParamsValues);

                queryParams = { ...queryParams, filter: newFilter };
            }
            source.queryParameters = queryParams;
        }
        if (bodyParamsValues) {
            source.bodyParameters = removeEmptyParams(
                [PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH, DaznMethodKeys.DAZN_SEARCH].includes(
                    selectedMethod
                )
                    ? removeSearchFilters()
                    : bodyParamsValues
            );
        }
        if (pathParamsValues) {
            source.pathParameters = removeEmptyParams(pathParamsValues);
        }
        if (!_.isEmpty(additionalFieldsValues)) {
            Object.keys(additionalFieldsValues).forEach((field) => {
                if (additionalFieldsValues[field] !== undefined && additionalFieldsValues[field] !== null) {
                    _.set(source, field, additionalFieldsValues[field]);
                }
            });
        }

        switch (source.service) {
            case 'horizon_sports':
            case 'motor_racing':
                source.pathParameters.type = horizonSportsDefaultAssetType;
                break;
            case 'ted_talks':
                source.pathParameters.type = tedTalksDefaultAssetType;
                break;
            case 'swr3':
                source.pathParameters.type = swr3DefaultAssetType;
                break;
            default:
                break;
        }

        onSave && onSave(source);
        onCloseClick();
    };

    const saveButton: DialogButton = {
        label: 'Save',
        type: 'BLUE',
        onClick: onSaveClick
    };

    const cancelButton: DialogButton = {
        label: 'Cancel',
        type: 'DEFAULT',
        onClick: onCloseClick
    };

    const buildSubServices = () => {
        const subServices = Services.find((s) => s.key === selectedService)?.subServices;

        if (!subServices?.length) return null;

        const options = subServices.map((elem) => {
            return {
                value: elem,
                label: _.get(ServiceSubserviceNames, elem, elem)
            };
        });
        return (
            <DialogDropdownSingle
                placeholder={'Select Sub Service'}
                options={options}
                clearable
                withTopMargin
                value={options.find((elem: any) => elem.value === selectedSubService) || ''}
                onChange={(evt: any) => {
                    setSelectedSubService(evt.value);
                    setSelectedMethod('');
                    setErrors({});
                    clearParams();
                }}
            />
        );
    };

    const buildDynamicSourceField = (
        key: string,
        value: any,
        valueType: 'string' | 'boolean' | 'array',
        paramType: 'query' | 'path' | 'additional',
        values?: any[]
    ) => {
        const fieldKey = `param_${key}_${paramType}_${valueType}`;

        // when changing a query or additional param, we must identify if it has any depending fields
        // if it does, we must clear the values if the param is changed
        const findDependentKeys = (obj: any, keyToCheck: string, value?: string): string[] => {
            const dependentKeys: string[] = [];

            const traverseObject = (obj: any, currentKey: string, dependsOnKey: string | null) => {
                if (obj && typeof obj === 'object') {
                    if (Array.isArray(obj)) {
                        obj.forEach((item, index) => traverseObject(item, `${currentKey}[${index}]`, dependsOnKey));
                    } else {
                        Object.entries(obj).forEach(([subKey, subValue]) => {
                            if (subKey === 'dependsOn') {
                                if (
                                    dependsOnKey &&
                                    value &&
                                    (subValue as any).some(
                                        (dependency: { key: string; values: string[] }) =>
                                            dependency.key === keyToCheck && dependency.values.includes(value)
                                    )
                                ) {
                                    dependentKeys.push(currentKey);
                                }
                            }
                            traverseObject(subValue, `${currentKey}.${subKey}`, dependsOnKey === null ? subKey : dependsOnKey);
                        });
                    }
                }
            };

            traverseObject(obj, 'obj', null);

            return dependentKeys;
        };

        const onChange = (e: any) => {
            switch (paramType) {
                case 'query':
                    setQueryParamsValues(() => {
                        const newValue =
                            valueType === 'array'
                                ? MultipleSelectFields.includes(key)
                                    ? e.map((elem: any) => elem.value)
                                    : e.value
                                : valueType === 'boolean'
                                ? !queryParamsValues[key]
                                : e.target.value;

                        const oldValue = value;

                        const newParams = {
                            ...queryParamsValues,
                            [key]: newValue
                        };

                        if (oldValue === newValue) return newParams;

                        const params =
                            Services.find((s) => s.key === selectedService)?.methods.find(
                                (m) => (!m.subService || m.subService === selectedSubService) && m.key === selectedMethod
                            )?.params || {};

                        const keys = findDependentKeys(params, key, oldValue);

                        keys.forEach((fullKey) => {
                            const key = _.last(fullKey.split('.'));
                            key && delete newParams[key];
                        });

                        return newParams;
                    });

                    break;
                case 'path':
                    setPathParamsValues({
                        ...pathParamsValues,
                        [key]: valueType === 'array' ? e.value : valueType === 'boolean' ? !pathParamsValues[key] : e.target.value
                    });
                    break;
                case 'additional':
                    setAdditionalFieldsValues(() => {
                        const newValue =
                            valueType === 'array' ? e.value : valueType === 'boolean' ? !additionalFieldsValues[key] : e.target.value;

                        const oldValue = value;

                        const newParams = {
                            ...additionalFieldsValues,
                            [key]: newValue
                        };

                        if (oldValue === newValue) return newParams;

                        const additionalFields = Services.find((s) => s.key === selectedService)?.additionalFields || {};

                        const keys = findDependentKeys(additionalFields, key, oldValue);

                        keys.forEach((fullKey) => {
                            const key = _.last(fullKey.split('.'));
                            key && delete newParams[key];
                        });

                        return newParams;
                    });
                    break;
                default:
                    break;
            }
        };
        switch (valueType) {
            case 'string':
                return (
                    <>
                        <DialogTextField
                            key={fieldKey}
                            value={value}
                            withTopMargin
                            onChange={onChange}
                            label={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        />
                    </>
                );
            case 'boolean':
                return (
                    <DialogToggleButton
                        key={fieldKey}
                        text={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        checked={value}
                        toggleCallback={() => onChange(null)}
                    />
                );
            case 'array':
                const options = !values
                    ? []
                    : values.map((elem: string) => {
                          return {
                              value: elem,
                              label: _.get(
                                  selectedMethod === 'stingray_karaoke_genres' ? StingrayGenresLabels : ServiceParamOptionLabels,
                                  elem,
                                  _.capitalize(elem.replace(/["_]/g, ' ').trim())
                              )
                          };
                      });

                if (MultipleSelectFields.includes(key)) {
                    return (
                        <DialogDropdownMultiple
                            key={selectedMethod + key}
                            placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                            value={value?.map(
                                (elem: any) => options.find((option: any) => option.value === elem || `"${option.value}"` === elem) || ''
                            )}
                            onChange={onChange}
                            options={options}
                            allowSelectAll
                            withTopMargin
                            clearable
                        />
                    );
                }
                return (
                    <DialogDropdownSingle
                        key={fieldKey}
                        placeholder={_.get(ServiceParamNames, key, capitalizeAndSplitCamelCaseString(key.replace(/["_]/g, ' ').trim()))}
                        value={options.find((elem: any) => elem.value === value) || ''}
                        onChange={onChange}
                        options={options}
                        withTopMargin
                        clearable
                    />
                );
            default:
                return <></>;
        }
    };

    const buildAdditionalFields = () => {
        const additionalFields = Services.find((s) => s.key === selectedService)?.additionalFields;
        if (!additionalFields || !Object.keys(additionalFields).length) return <></>;

        const fields: any[] = [];
        fields.push(
            <InputLabelWithIconWrapper key={'additional_fields_label'}>
                Additional Fields
                {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_additional_fields')}
            </InputLabelWithIconWrapper>
        );

        Object.keys(additionalFields).forEach((field) => {
            const dependencies = additionalFields[field].dependsOn;

            if (dependencies && !dependencies.some((dep: any) => dep.values.includes(additionalFieldsValues[dep.key]))) {
                return;
            }

            const isArray = Array.isArray(additionalFields[field]) || Array.isArray(additionalFields[field].values);
            fields.push(
                buildDynamicSourceField(
                    field,
                    additionalFieldsValues[field],
                    isArray ? 'array' : additionalFields[field],
                    'additional',
                    Array.isArray(additionalFields[field]) ? additionalFields[field] : additionalFields[field].values
                )
            );
        });
        return <ParamsContainer>{fields}</ParamsContainer>;
    };

    const buildParamFields = () => {
        const params = Services.find((s) => s.key === selectedService)?.methods.find(
            (m) => m.key === selectedMethod && (selectedSubService && m.subService ? m.subService === selectedSubService : true)
        )?.params;
        if (!params) return <></>;

        const fields: any[] = [];

        if (Object.keys(params.path || {}).length) {
            fields.push(
                <InputLabelWithIconWrapper key={'path_params_label'}>
                    Path Parameters
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_path_parameters')}
                </InputLabelWithIconWrapper>
            );
            Object.keys(params.path).forEach((param) => {
                fields.push(
                    buildDynamicSourceField(
                        param,
                        pathParamsValues[param],
                        Array.isArray(params.path[param]) ? 'array' : params.path[param],
                        'path',
                        Array.isArray(params.path[param]) ? params.path[param] : undefined
                    )
                );
            });
        }

        if (Object.keys(params.query || {}).length) {
            fields.push(
                <InputLabelWithIconWrapper key={'query_params_label'}>
                    Query Parameters
                    {renderTooltipWithKey(<SVGInline src={icons.infoIcon} />, 'sources_query_parameters')}
                </InputLabelWithIconWrapper>
            );

            //check if there are filter queries applied
            const filterQueries = Object.keys(params.query).filter((param) => param.includes('$'));
            if (filterQueries.length) {
                filterQueries.forEach((key, index) => {
                    const [generalKey, filterKey] = key.split('$');

                    if (params.query[key] === 'string') {
                        fields.push(
                            <DialogTextField
                                optional
                                withTopMargin
                                key={`param_filter_${index}`}
                                value={filterParamsValues[filterKey] || ''}
                                onChange={(e: any) =>
                                    setFilterParamsValues((oldParams: any) => {
                                        const newParams = {
                                            ...oldParams,
                                            [filterKey]: e.target.value
                                        };
                                        return newParams;
                                    })
                                }
                                label={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                toolTipText={filterKey === 'id' ? 'Multiple Ids should be separated by comma' : undefined}
                            />
                        );
                    }

                    if (Array.isArray(params.query[key])) {
                        const options = params.query[key].map((elem: string) => {
                            return {
                                value: elem,
                                label: _.get(FilterParamOptionsLabels, elem, _.capitalize(elem.replace(/["_]/g, ' ').trim()))
                            };
                        });

                        if (MultipleSelectFields.includes(filterKey)) {
                            const value = options.filter((item: any) => filterParamsValues[filterKey]?.includes(item.value));

                            fields.push(
                                <DialogDropdownMultiple
                                    optional
                                    withTopMargin
                                    value={value}
                                    options={options}
                                    key={`path_filter_${index}`}
                                    placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                    onChange={(newValue: any) =>
                                        setFilterParamsValues((oldParams: any) => ({
                                            ...oldParams,
                                            [filterKey]: newValue?.map((elem: any) => elem.value)
                                        }))
                                    }
                                    allowSelectAll
                                />
                            );
                        } else {
                            fields.push(
                                <DialogDropdownSingle
                                    optional
                                    options={options}
                                    clearable
                                    withTopMargin
                                    key={`path_filter_${index}`}
                                    value={options.find((elem: any) => elem.value === filterParamsValues[filterKey]) || ''}
                                    placeholder={`${_.capitalize(generalKey)} (${_.capitalize(filterKey.replace(/["_]/g, ' ').trim())})`}
                                    onChange={(evt: any) => {
                                        setFilterParamsValues((oldParams: any) => ({ ...oldParams, [filterKey]: evt.value }));
                                    }}
                                />
                            );
                        }
                    }
                });
            }

            Object.keys(params.query)
                .filter((param) => !param.includes('$'))
                .forEach((param) => {
                    const paramObj = params.query[param];
                    const dependencies = paramObj.dependsOn;
                    if (dependencies && !dependencies.some((dep: any) => dep.values.includes(queryParamsValues[dep.key]))) {
                        return;
                    }

                    fields.push(
                        buildDynamicSourceField(
                            param,
                            queryParamsValues[param],
                            Array.isArray(dependencies ? params.query[param].values : params.query[param])
                                ? 'array'
                                : dependencies
                                ? params.query[param].values
                                : params.query[param],
                            'query',
                            Array.isArray(dependencies ? params.query[param].values : params.query[param])
                                ? dependencies
                                    ? params.query[param].values
                                    : params.query[param]
                                : undefined
                        )
                    );
                });
        }

        return <ParamsContainer>{fields}</ParamsContainer>;
    };

    const validateSource = () => {
        const newErrors = { ...errors };
        newErrors.name = validator({ required: true }, name);
        newErrors.service = validator({ required: true }, selectedService);
        newErrors.method = validator({ required: true }, selectedMethod);

        if (([YOUTUBE_METHOD_KEYS.RECOMMENDATIONS, YOUTUBE_METHOD_KEYS.RECOMMENDATIONS_SEARCH] as string[]).includes(selectedMethod)) {
            let bodyParamsErrors = { ...(newErrors.bodyParams || {}) };
            bodyParamsErrors.region_code = validator({ required: true }, bodyParamsValues.region_code);
            bodyParamsErrors.language_code = validator({ required: true }, bodyParamsValues.language_code);
            if (
                !Object.keys(bodyParamsValues || {}).some(
                    (key) => key.includes('shelf_params.') && key !== 'shelf_params.isShorts' && bodyParamsValues[key]
                )
            ) {
                if (selectedMethod === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS && !bodyParamsValues.shelf_params) {
                    bodyParamsErrors.shelf_params = 'A shelf param is required';
                } else {
                    Object.values(YOUTUBE_RECO_SHELF_PARAMS).forEach((elem) => {
                        bodyParamsErrors[elem] = 'A shelf param is required';
                    });
                }
            } else {
                bodyParamsErrors = _.omit(bodyParamsErrors, ['shelf_params', ...Object.values(YOUTUBE_RECO_SHELF_PARAMS)]);
            }
            if (Object.values(bodyParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'bodyParams', bodyParamsErrors);
            } else {
                delete newErrors.bodyParams;
            }
        }

        if (
            (
                [
                    YOUTUBE_METHOD_KEYS.VIDEOS,
                    YOUTUBE_METHOD_KEYS.PLAYLISTS,
                    YOUTUBE_METHOD_KEYS.SEARCH,
                    YOUTUBE_METHOD_KEYS.TRENDING
                ] as string[]
            ).includes(selectedMethod) &&
            (selectedService === contentSourceTypes.YOUTUBE || selectedService === contentSourceTypes.YOUTUBE_RECO)
        ) {
            let queryParamsErrors = { ...(newErrors.queryParams || {}) };
            const query = YOUTUBE_FIELD_LABELS_MAP.get(selectedMethod);
            if (Array.isArray(query)) {
                const keys = query.map((param) => param.key);
                if (!keys.some((key) => !!queryParamsValues[key])) {
                    queryParamsErrors[selectedMethod] = 'At least one of the params is required';
                } else delete queryParamsErrors[selectedMethod];
            } else {
                const key = query?.key || '';
                queryParamsErrors[key] = validator({ required: true }, queryParamsValues[key]);
            }

            if (Object.values(queryParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'queryParams', queryParamsErrors);
            } else {
                delete newErrors.queryParams;
            }
        }

        if (Object.keys(PrimeAndDisneyMethods).includes(selectedMethod)) {
            let bodyParamsErrors = { ...(newErrors.bodyParams || {}) };
            let pathParamsErrors = { ...(newErrors.pathParams || {}) };
            bodyParamsErrors.languages = validator({ required: true }, bodyParamsValues.languages);
            bodyParamsErrors.sort = validator({ required: true }, bodyParamsValues.sort);
            pathParamsErrors.type = validator({ required: true }, pathParamsValues.type);

            if ([PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH].includes(selectedMethod)) {
                bodyParamsErrors['filter.id'] = validator({ required: true }, bodyParamsValues['filter.id']);
            }

            if (Object.values(bodyParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'bodyParams', bodyParamsErrors);
            } else {
                delete newErrors.bodyParams;
            }

            if (Object.values(pathParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'pathParams', pathParamsErrors);
            } else {
                delete newErrors.pathParams;
            }
        }

        if (Object.keys(DaznMethods).includes(selectedMethod)) {
            let bodyParamsErrors = { ...(newErrors.bodyParams || {}) };
            bodyParamsErrors[streamingRightsFilterKey] = validator({ required: true }, bodyParamsValues[streamingRightsFilterKey]);

            if ([DaznMethodKeys.DAZN_SEARCH].includes(selectedMethod)) {
                bodyParamsErrors['filter.id'] = validator({ required: true }, bodyParamsValues['filter.id']);
            }

            if (Object.values(bodyParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'bodyParams', bodyParamsErrors);
            } else {
                delete newErrors.bodyParams;
            }
        }

        if (selectedService === contentSourceTypes.ANDROID && multipleAndroidFieldMethods.includes(selectedMethod)) {
            let queryParamsErrors = { ...(newErrors.queryParams || {}) };
            const apps = [...(queryParamsValues?.apps || [])];
            queryParamsErrors.apps = apps.map((app) => validator({ required: true }, app));

            if (queryParamsErrors.apps.filter((value: string) => !!value).length) {
                _.set(newErrors, 'queryParams', queryParamsErrors);
            } else {
                delete newErrors.queryParams;
            }
        }

        if (selectedService === contentSourceTypes['3READY_ASSETS']) {
            let bodyParamsErrors = { ...(newErrors.bodyParams || {}) };
            bodyParamsErrors.country = validator({ required: true }, bodyParamsValues.country);

            if (Object.values(bodyParamsErrors).filter((value) => !!value).length) {
                _.set(newErrors, 'bodyParams', bodyParamsErrors);
            } else {
                delete newErrors.bodyParams;
            }
        }

        setErrors(newErrors);
        return Object.values(newErrors).filter((value) => !!value).length === 0;
    };

    useEffect(() => {
        if (!dynamicSource) {
            setAdditionalFieldsValues(ServiceParamDefaultValues);
            return;
        }

        const queryParameters = dynamicSource.queryParameters || {};
        if (dynamicSource.method === 'channels_api' && queryParameters.apps) {
            setQueryParamsValues({ ...queryParameters, apps: queryParameters.apps[0] });
        } else {
            setQueryParamsValues(queryParameters);
        }

        setName(dynamicSource.name);
        setSelectedService(dynamicSource.service);
        setSelectedSubService(dynamicSource.subService || '');
        setSelectedMethod(dynamicSource.method);
        setBodyParamsValues(dynamicSource.bodyParameters || {});
        setPathParamsValues(dynamicSource.pathParameters || {});
        setFilterParamsValues(queryParameters?.[FILTER_PARAM] || {});
        setUrl(dynamicSource.url || '');

        const additionalFieldsValues: any = {};
        (Object.keys(dynamicSource) as Array<keyof typeof dynamicSource>).forEach((key) => {
            if (additionalSourceFields.includes(key)) {
                additionalFieldsValues[key] = dynamicSource[key];
            }
        });

        setAdditionalFieldsValues(additionalFieldsValues);
    }, [dynamicSource]);

    useEffect(() => {
        if (!selectedService || selectedService !== contentSourceTypes.ANDROID) return;
        loadApplications();
    }, [selectedService]);

    useEffect(() => {
        if (!open) return;
        setServiceOptions(
            Services.map((service) => {
                return {
                    value: service.key,
                    label: service.title
                };
            })
        );
        if (preselectedService) {
            const service = Services.find((s) => s.key === preselectedService);
            service && setSelectedService(service.key);
        }
    }, [Services, preselectedService, servicesLoading, open]);

    useEffect(() => {
        if (!selectedService) return;
        let methodsPerService = Services.find((service) => service.key === selectedService)?.methods;
        if (!methodsPerService) return;

        if (selectedSubService) {
            methodsPerService = methodsPerService.filter((elem) => elem.subService === selectedSubService);
        }

        setMethodOptions(
            methodsPerService.map((method) => {
                return {
                    value: method.key,
                    label: method.title
                };
            })
        );
    }, [selectedService, selectedSubService]);

    useEffect(() => {
        setShowPreview(!!selectedMethod);
        setIsPreviewOpen(!!selectedMethod);
    }, [selectedMethod]);

    useEffect(() => {
        if (!Services || !selectedService) return;

        setUrl(
            createDynamicUrl(
                Services,
                selectedService,
                selectedMethod,
                queryParamsValues,
                pathParamsValues,
                filterParamsValues,
                [PrimeAndDisneyMethodKeys.AMAZON_SEARCH, PrimeAndDisneyMethodKeys.DISNEY_SEARCH, DaznMethodKeys.DAZN_SEARCH].includes(
                    selectedMethod
                )
                    ? removeSearchFilters()
                    : bodyParamsValues,
                selectedSubService
            )
        );
    }, [Services, selectedService, selectedMethod, queryParamsValues, pathParamsValues, filterParamsValues, bodyParamsValues]);

    useEffect(() => {
        setIsOpen(open);
        if (open && (!Services.length || activeProjectId !== loadedServicesProjectId)) {
            loadServices(activeProjectId || '');
            setLoadedServicesProjectId(activeProjectId);
        }
    }, [open, activeProjectId]);

    useEffect(() => {
        if (selectedService) {
            setRequiresSubService(!!Services.find((service) => service.key === selectedService)?.subServices?.length);
        }
    }, [selectedService]);

    const removeSearchFilters = () => {
        // for the search methods, the other filters are just helpers. they should not be added to the final source/preview
        const bodyParams = { ...bodyParamsValues };
        Object.keys(bodyParams).forEach((key) => {
            if (key.includes('filter') && key !== 'filter.id') {
                delete bodyParams[key];
            }
        });
        return bodyParams;
    };

    const renderSelectedServiceFields = () => {
        const service = Services.find((s) => s.key === selectedService);
        if (!service) return null;
        switch (selectedService) {
            case contentSourceTypes.YOUTUBE:
            case contentSourceTypes.YOUTUBE_RECO:
                return (
                    <YoutubeSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, queryParamsValues, bodyParamsValues, errors }}
                        fieldSetters={{ setSelectedMethod, setPathParamsValues, setQueryParamsValues, setBodyParamsValues, setErrors }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            case contentSourceTypes.PRIME_VIDEO:
            case contentSourceTypes.DISNEY_PLUS:
                return (
                    <PrimeAndDisneySourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, bodyParamsValues, errors }}
                        fieldSetters={{ setSelectedMethod, setPathParamsValues, setQueryParamsValues, setBodyParamsValues, setErrors }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            case contentSourceTypes.DAZN:
                return (
                    <DaznSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, bodyParamsValues, pathParamsValues, errors }}
                        fieldSetters={{ setSelectedMethod, setBodyParamsValues, setQueryParamsValues, setPathParamsValues, setErrors }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            case contentSourceTypes.ANDROID:
                return (
                    <AndroidSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, queryParamsValues, errors }}
                        fieldSetters={{
                            setSelectedMethod,
                            setPathParamsValues,
                            setQueryParamsValues,
                            setErrors,
                            setAddMultipleApps,
                            setShowNewApplicationDialog
                        }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            case contentSourceTypes.SWR3:
                const options =
                    service.methods[0].params.body['filter.genres.title'].map((value: any) => {
                        return {
                            value,
                            label: SWR3_LABELS[value as keyof typeof SWR3_LABELS]
                        };
                    }) || [];
                const value = options.find((option: any) => option.value === bodyParamsValues['filter.genres.title']) || '';

                return (
                    <>
                        {buildSubServices()}
                        {requiresSubService && !selectedSubService ? null : (
                            <DialogDropdownSingle
                                value={methodOptions.find((source) => source.value === selectedMethod) || ''}
                                options={methodOptions}
                                clearable
                                placeholder={'Select Method'}
                                onChange={(value: any) => {
                                    setErrors(_.omit(errors, 'method'));
                                    setSelectedMethod(value.value);
                                    clearParams();
                                }}
                                error={errors.method}
                            />
                        )}
                        {selectedMethod && (
                            <>
                                <DialogDropdownSingle
                                    value={value}
                                    options={options}
                                    placeholder={'Select Genre'}
                                    onChange={(value: any) => {
                                        setErrors(_.omit(errors, 'method'));
                                        setBodyParamsValues((prevState: any) => {
                                            return {
                                                ...prevState,
                                                'filter.genres.title': value.value
                                            };
                                        });
                                    }}
                                    clearable
                                    error={errors.method}
                                />
                            </>
                        )}
                    </>
                );

            case contentSourceTypes['3READY_ASSETS']:
                return (
                    <PlatformAssetsSourceFields
                        service={service}
                        fieldValues={{ selectedMethod, pathParamsValues, bodyParamsValues, errors }}
                        fieldSetters={{ setSelectedMethod, setPathParamsValues, setBodyParamsValues, setQueryParamsValues, setErrors }}
                        dropdownOptions={{ methodOptions }}
                    />
                );
            default:
                return (
                    <>
                        {buildSubServices()}
                        {requiresSubService && !selectedSubService ? null : (
                            <DialogDropdownSingle
                                value={methodOptions.find((source) => source.value === selectedMethod) || ''}
                                options={methodOptions}
                                clearable
                                withTopMargin
                                placeholder={'Select Method'}
                                onChange={(value: any) => {
                                    setErrors(_.omit(errors, 'method'));
                                    setSelectedMethod(value.value);
                                    clearParams();
                                }}
                                error={errors.method}
                            />
                        )}
                        {selectedMethod && (
                            <>
                                {buildParamFields()}
                                {buildAdditionalFields()}
                            </>
                        )}
                    </>
                );
        }
    };

    // if we have an assetstore source, there are 2 ways to preview it - with a get request (like the clients apps and if the mongo filter is created) or with the clasic post request
    // however, when editing, if the parameters are changing, we need to go with the post request because the mongo filter changes, and the new one will be inserted only at save
    // this mechanism is based on wether the sourceId is sent to the preview controller in the BE or not. this function calculates wether we send the id or not
    const shouldSendIdToPreview = () => {
        const bodyParams = dynamicSource?.bodyParameters ? { ...dynamicSource.bodyParameters } : undefined;
        if (bodyParams?.languages?.length && DaznMethodKeys.DAZN_SEARCH === selectedMethod) {
            bodyParams[streamingRightsFilterKey] = bodyParams.languages[0];
        }
        return (
            platformAssetstoreServices.includes(selectedService) &&
            !!dynamicSource?._id &&
            _.isEqual(removeEmptyParams(bodyParamsValues), bodyParams) &&
            _.isEqual(removeEmptyParams(pathParamsValues), dynamicSource?.pathParameters)
        );
    };

    const previewOpen =
        isPreviewOpen &&
        (selectedMethod === YOUTUBE_METHOD_KEYS.RECOMMENDATIONS
            ? Object.keys(bodyParamsValues).some((key) => key.includes('shelf_params.'))
            : true);

    if (!isOpen) return null;

    return (
        <GenericDialog
            actionButtons={[cancelButton, saveButton]}
            title={dynamicSource ? 'Edit Source' : 'Create Source'}
            type={DialogTypes.Form}
            onClose={onCloseClick}
            data-cy={'create-source-dialog'}
            circlesSlugOptions={{ default: CIRCLE_SLUGS.sources }}
        >
            <DialogTextField
                value={name}
                onChange={(e: any) => {
                    setName(e.target.value);
                    setErrors(_.omit(errors, 'name'));
                }}
                placeholder={' Name'}
                label={'Source Name'}
                error={errors.name}
                dataCy={'source-name-field'}
            />
            <DialogDropdownSingle
                value={serviceOptions.find((service) => service.value === selectedService) || ''}
                options={serviceOptions}
                clearable
                placeholder={'Select Service'}
                onChange={(value: any) => {
                    setErrors(_.omit(errors, 'service'));
                    setSelectedService(value.value);
                    setSelectedSubService('');
                    setSelectedMethod('');
                    setQueryParamsValues({});
                    setPathParamsValues({});
                    setBodyParamsValues({});
                    setFilterParamsValues({});
                    setErrors({});
                }}
                isDisabled={servicesLoading || !!(preselectedService && selectedService)}
                dataCy={'source-service-field'}
                error={errors.service}
                withTopMargin
            />

            {selectedService && renderSelectedServiceFields()}
            {showPreview && (
                <DataPreviewDialog
                    previewType={DialogPreviewTypes.SOURCE}
                    isPreviewOpen={previewOpen}
                    service={selectedService}
                    url={url}
                    sourceId={shouldSendIdToPreview() ? dynamicSource?._id : undefined}
                    togglePreview={() => {
                        setIsPreviewOpen(!isPreviewOpen);
                    }}
                />
            )}

            <ApplicationDialog
                open={showNewApplicationDialog}
                onClose={() => {
                    setShowNewApplicationDialog(false);
                }}
                onSave={(app) => createApp(app)}
            />
        </GenericDialog>
    );
};
